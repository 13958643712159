$(document).ready(function() {

  AOS.init({
    duration: 1200,
  })


  function bounceUp(){
      $('.scrolldown').animate({'margin-top':'40px'}, 300, bounceDown);
  }
  function bounceDown(){
      $('.scrolldown').animate({'margin-top':'30px'}, 300);
      setTimeout(
        function()
        {
          bounceUp();
        }, 2000);
  }

  bounceUp();



  $( ".menuicon" ).click(function() {
      if($('.menu-mobile').hasClass('.open')) {

        $(".menu-mobile").animate({'top':'-120vh'}, 800);
        $(".menu-mobile").removeClass('.open');
        $("body").css('overflow','auto');
        $('.tttt').attr('xlink:href',"/img/sprite.svg#menu-mob");



      } else {
        $(".menu-mobile").css('height','auto');
        $(".menu-mobile").animate({'top':'0px'}, 800);
        $("body").css('overflow','hidden');
        $(".menu-mobile").css('height','100vh');
        $(".menu-mobile").addClass('.open');
        $('.tttt').attr('xlink:href',"/img/sprite.svg#close");



      }
  });



  $(document).on("mouseenter", ".grid-item", function() {
                $(this).find(".text").append('<span class="tick"> >> </span>');
                $(this).find(".text").css("color","white");
            }).on("mouseleave", ".grid-item", function() {
                $(this).find('span.tick').remove();
                $(this).find(".text").css("color","#FAAF3B");

            });







    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 200
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });




    //
    // $(document.body).on('click', '.vjs-fullscreen-control' , function(){
    //     // note this is a guess, it could be $(this).parent, is the player you want.
    //     var player = $(this).closest('.video-js')
    //     if(player.classList.contains('vjs-fullscreen')) {
    //        player.closest('.vjs-fullscreen-control').className += " exit-full-screen";
    //     } else {
    //        player.closest('.vjs-fullscreen-control').classList.remove("exit-full-screen");
    //     }
    // });



    var settingsIcon = document.querySelector('.vjs-big-play-button');
    if (settingsIcon != null ) {
          settingsIcon.removeChild(settingsIcon.firstChild);
          //settingsIcon.className += 'your_icon_class_here';
    }


    var swipert = new Swiper('.swiper-talent', {
      loop: false,
      slideToClickedSlide:true,
      preventInteractionOnTransition: true,
      simulateTouch:false,

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      /* ON INIT AUTOPLAY THE FIRST VIDEO */
      on: {
        init: function () {
          console.log('swiper initialized');
          var currentVideo =  $("[data-swiper-slide-index=" + this.realIndex + "]").find("video");
          currentVideo.trigger('play');
        },


        slideChangeTransitionStart: function (swipertalent) {

              			if ($(".swiper-slide-next video")[0])
              			$(".swiper-slide-next video")[0].pause();
                    var player = $(".swiper-slide-next video")[0];
              			if ($(".swiper-slide-prev video")[0])
              				$(".swiper-slide-prev video")[0].pause();
              			if ($(".swiper-slide-active video")[0])
              				$(".swiper-slide-active video")[0].play();
                      // var active = $(".swiper-slide-active video").attr("id");
                      // active.on("useractive", function() {
                      //   active.bigPlayButton.show();
                      // });
                      //
                      // active.on("userinactive", function() {
                      //   active.bigPlayButton.hide();
                      // });
                      // console.log(active);
              		},
              		slideChangeTransitionEnd: function (swipertalent) {
              			$(".swiper-slide-active video").on("ended", function () {
              				swipertalent.slideNext();
                      swiper.slideTo(0);

              			});
              		},
      },
    });

    /* GET ALL VIDEOS */
    var sliderVideos = $(".swiper-slide video");

    swipert.on('slideChange', function () {
      console.log('slide changed');
      sliderVideos.each(function( index ) {
        this.currentTime = 0;
        this.pause();
        //this.bigPlayButton.show();
      });

      /* SWIPER GET CURRENT AND PREV SLIDE (AND VIDEO INSIDE) */
    //  var prevVideo =  $(".swiper-slide-prev").find("video");
    //var currentVideo =  $(".swiper-slide-active")[0].find("video");
    //  var nextVideo =  $(".swiper-slide-next").find("video");
    //  prevVideo.trigger('stop');
    //  currentVideo.play();
    //  console.log(currentVideo);
      //	$(".swiper-slide-active video")[0].play();
    });

   //
   //  $(".grid-2 a").click(function(){
   //    $('html, body').animate({
   //     scrollTop: $(".videostage").offset().top
   // }, 1000);
   //     var index=$(this).attr('title');
   //      swipert.slideTo(index);
   //  });



    // $("#my-video01").on('mouseover', function() {
    //   controlBar.addClass('vjs-fade-out');
    // });


    $(function(){
      if($('body').is('.talent')){

    $(document.body).on('click', '.vjs-big-play-button' , function(){
        // note this is a guess, it could be $(this).parent, is the player you want.
        var rr = $(this);
        //console.log(rr);
        var pl = rr.closest('div').find('video').attr('id');
        //console.log(pl);
        if(rr.hasClass("playy")) {
          document.getElementById(pl).play();

        } else {

          document.getElementById(pl).pause();

        }
    });


    videojs.options.muted = true;
    videojs.options.preload = false;
    videojs.options.autoplay = false;
    videojs.options.autoSetup = false;


    var vid = Array(videojs("my-video01"), videojs("my-video02"));
    vid.forEach(function (player) {



      $('.vjs-big-play-button').addClass('playy');


      // $('.vjs-big-play-button').click(function() {
      //   if (player.paused()) {
      //     player.play();
      //   }
      //   else {
      //     player.pause();
      //   }
      // });

      // $('.vjs-big-play-button').click(function() {
      //
      //   if(player.paused() == true){
      //   player.play();
      //   }
      //   else {
      //     player.pause();
      //   }
      // });

        player.on("pause", function () {
        $('.vjs-big-play-button').fadeIn("fast");
        $('.vjs-big-play-button').removeClass('pausee');
        $('.vjs-big-play-button').addClass('playy');

        // $('.vjs-big-play-button').click(function() {
        //
        //   player.play();
        // });
        //$('#'+ player.id() +' #accordion-section-title').removeClass('playing');
        console.log(player.id() + " pause");
      });



      player.on("play", function () {
        //$('#'+ player.id() +' #accordion-section-title').addClass('playing');
        $('.vjs-big-play-button').fadeIn("fast");
        // $('.vjs-big-play-button').click(function() {
        //
        //   player.pause();
        // });
        $('.vjs-big-play-button').removeClass('playy');
        $('.vjs-big-play-button').addClass('pausee');


        // console.log(player.id() + " play");
        // $('.vjs-big-play-button').click(function() {
        //   var vv = document.getElementById("my-video01_html5_api");
        //   vv.pause();
        //   console.log(player.id() + " clicked");
        // });

      });

      player.on("useractive", function() {
        // $(".vjs-big-play-button , .vjs-control-bar").fadeIn("fast");
         $('.vjs-big-play-button').fadeIn("fast");
         console.log(player.id() + " active");

      });

      player.on("userinactive", function() {
         //$(".vjs-big-play-button , .vjs-control-bar").fadeOut("slow");
         $('.vjs-big-play-button').fadeOut("fast");
         console.log(player.id() + " not active");

      });





          // $('.vjs-big-play-button').click(function(){
          //     if($(this).hasClass('play'))
          //     {
          //          player.pause();
          //     }
          //     else
          //     {
          //         player.play();
          //     }
          // });


    });


  }
});


//
//     var vid = videojs("my-video01, my-video02", {
//       controls: true,
// autoplay: false,
// preload: 'auto'
//     });
//
//     vid.on("play", function() {
//       //$(".menu-btn").removeClass("vjs-fade-out");
//        $(".vjs-big-play-button").css("display","block");
//       console.log("playing");
//     });
//
//     vid.on("useractive", function() {
//       //$(".menu-btn").removeClass("vjs-fade-out");
//        $(".vjs-big-play-button , .vjs-control-bar").fadeIn("fast");
//       // $(".vjs-control-bar").fadeIn("slow");
//       console.log("active");
//     });
//
//     vid.on("userinactive", function() {
//       //$(".menu-btn").addClass("vjs-fade-out");
//        $(".vjs-big-play-button , .vjs-control-bar").fadeOut("slow");
//        //$(".vjs-control-bar").fadeOut("slow");
//
//       console.log("not active");
//
//     });


    // var playerbutton = $("my-video01")
    // playerbutton.on("userinactive", function() {
    //   $(".vjs-big-play-button").css("display","none");
    // });


//
//     $(document.body).on('mouseover', '.myvid' , function(){
//          // note this is a guess, it could be $(this).parent, is the player you want.
// //         var player = $(this).closest('.video-js');
//          var player = $(this).attr('id');
//          var playerbutton = $(this).closest('.vjs-big-play-button');
//          playerbutton.css("display","block");
//
//          console.log(player);
//          // if(player.classList.contains('vjs-fullscreen')) {
//          //    player.closest('.vjs-fullscreen-control').className += " exit-full-screen";
//          // } else {
//          //    player.closest('.vjs-fullscreen-control').classList.remove("exit-full-screen");
//          // }
//      });

    // if($(".myvid").hasClass("vjs-paused")){
    //   $(".vjs-big-play-button").css("display","block");
    // }
    //
    // else if($(".myvid").hasClass("vjs-user-active")){
    //   $(".vjs-big-play-button").css("display","block");
    // }
    // else if($(".myvid").hasClass("vjs-user-inactive")){
    //   $(".vjs-big-play-button").css("display","none");
    // }
    // else{
    //   $(".vjs-big-play-button").css("display","none");
    //
    // }



    // $(".myvid").mouseover(function(){
    //   if($(".myvid").hasClass("vjs-paused")){
    //     $(".vjs-big-play-button").css("display","block");
    //   }
    // });


    // $(".myvid").mouseover(function(){
    //   var player = this.id;
    //   $(".vjs-big-play-button").css("display","block");
    //     console.log(player);
    // });
    //
    // $(".myvid").mouseout(function(){
    //   var player = this.id;
    //   $(".vjs-big-play-button").css("display","none");
    //     console.log(player);
    // });


    // var playerbutton = $("my-video01")
    // playerbutton.on("userinactive", function() {
    //   $(".vjs-big-play-button").css("display","none");
    // });

//
//       var player = $(".myvid").mouseover();
//       player.on("useractive", function() {
//         $(".vjs-big-play-button").css("display","block");
//
// });






    // var player = videojs('my-video01', {
    //    autoplay: 'muted'
    // });
    //
    // player.on('mouseout', function(){
    //   //controlBar.addClass('vjs-fade-out');
    //   $(".vjs-big-play-button").css("display","none");
    //
    // });
    //
    // player.on('mouseover', function(){
    //   //controlBar.removeClass('vjs-fade-out');
    //   $(".vjs-big-play-button").css("display","block");
    //
    // });

    //
    //
    // var swipertalent = new Swiper ('.swiper-talent', {
    //   // Optional parameters
    //   direction: 'horizontal',
    //   loop: false,
    //
    //
    //   // Navigation arrows
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    //
    //   init: function (swipertalent) {
    //   			if ($(".swiper-slide-active video")[0])
    //   				$(".swiper-slide-active video")[0].play();
    //   			$(".swiper-slide-active video").on("ended", function () {
    //   				swipertalent.slideNext();
    //   			});
    //   		},
    //
    //   on: {
    //
    //
    //
    //     slideChangeTransitionStart: function (swipertalent) {
    //
    //       			if ($(".swiper-slide-next video")[0])
    //       			$(".swiper-slide-next video")[0].pause();
    //             var player = $(".swiper-slide-next video")[0];
    //       			if ($(".swiper-slide-prev video")[0])
    //       				$(".swiper-slide-prev video")[0].pause();
    //       			if ($(".swiper-slide-active video")[0])
    //       				$(".swiper-slide-active video")[0].play();
    //       		},
    //       		slideChangeTransitionEnd: function (swipertalent) {
    //       			$(".swiper-slide-active video").on("ended", function () {
    //       				swipertalent.slideNext();
    //               swiper.slideTo(0);
    //
    //       			});
    //       		}
    //
    //   }
    // })





    //
    //
    //
    // // variable
    // var VIDEO_PLAYING_STATE = {
    //   "PLAYING": "PLAYING",
    //   "PAUSE": "PAUSE"
    // }
    // var videoPlayStatus = VIDEO_PLAYING_STATE.PAUSE
    // var timeout = null
    // var waiting = 3000
    // var swipertalent = new Swiper(
    //   '.swiper-talent', {
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    // });
    //
    // // HTML5 vdo object
    // var options = {};
    // var player = videojs('my-video', options);
    // player.on('ended', function() {
    //   next()
    // })
    //
    // // swiper object
    // swipertalent.on('slideChangeTransitionEnd', function () {
    //   var index = swipertalent.activeIndex
    //   var currentSlide =   $(swipertalent.slides[index])
    //   var currentSlideType = currentSlide.data('slide-type')
    //
    //   // incase user click next before video ended
    //   if (videoPlayStatus === VIDEO_PLAYING_STATE.PLAYING) {
    //     player.pause()
    //   }
    //
    //   clearTimeout(timeout)
    //
    //   switch (currentSlideType) {
    //     case 'img':
    //       runNext()
    //       break;
    //     case 'vdo':
    //       player.currentTime(0)
    //       player.play()
    //       videoPlayStatus = VIDEO_PLAYING_STATE.PLAYING
    //       break;
    //     default:
    //       throw new Error('invalid slide type');
    //   }
    // })
    //
    // // global function
    // function prev() {
    //   swipertalent.slidePrev();
    // }
    //
    // function next() {
    //   swipertalent.slideNext();
    // }
    //
    // function runNext() {
    //   timeout = setTimeout(function () {
    //     next()
    //   }, waiting)
    // }
    //
    // runNext()
    //





  //
  //
  //   const swipertalent = new Swiper(".swiper-talent", {
  //   	slidesPerView: 1,
  //   	slidesPerColumn: 1,
  //   	spaceBetween: 0,
  //     loop: false,
  //     noSwiping: true,
  //     navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
  //   	on: {
  //   		init: function (swipertalent) {
  //   			if ($(".swiper-slide-active video")[0])
  //   				//$(".swiper-slide-active video")[0].play();
  //   			$(".swiper-slide-active video").on("ended", function () {
  //   				swipertalent.slideNext();
  //   			});
  //   		},
  //   		slideChangeTransitionStart: function (swipertalent) {
  //   			if ($(".swiper-slide-next video")[0])
  //   			$(".swiper-slide-next video")[0].pause();
  //   			if ($(".swiper-slide-prev video")[0])
  //   				$(".swiper-slide-prev video")[0].pause();
  //   			if ($(".swiper-slide-active video")[0])
  //   				$(".swiper-slide-active video")[0].pause();
  //   		},
  //   		slideChangeTransitionEnd: function (swipertalent) {
  //   			$(".swiper-slide-active video").on("ended", function () {
  //   				swipertalent.slideNext();
  //           //swiper.slideTo(0);
  //   			});
  //   		}
  //   	}
  //   });
    //
    //
    // const progress = $(".progress");
    //
    // function progressLoop() {
    // setInterval(function () {
    // progress.value = Math.round((video.currentTime / video.duration) * 100);
    // });
    // }
    //
    // progressLoop();
    //









    const swiper = new Swiper(".swiper-container", {
    	slidesPerView: 1,
    	slidesPerColumn: 1,
    	spaceBetween: 0,
      loop: true,
    	on: {
    		init: function (swiper) {
    			if ($(".swiper-slide-active video")[0])
    				$(".swiper-slide-active video")[0].play();
    			$(".swiper-slide-active video").on("ended", function () {
    				swiper.slideNext();
    			});
    		},
    		slideChangeTransitionStart: function (swiper) {
    			if ($(".swiper-slide-next video")[0])
    				$(".swiper-slide-next video")[0].pause();
    			if ($(".swiper-slide-prev video")[0])
    				$(".swiper-slide-prev video")[0].pause();
    			if ($(".swiper-slide-active video")[0])
    				$(".swiper-slide-active video")[0].play();
    		},
    		slideChangeTransitionEnd: function (swiper) {
    			$(".swiper-slide-active video").on("ended", function () {
    				swiper.slideNext();
            //swiper.slideTo(0);
    			});
    		}
    	}
    });




    $( "body" ).click(function() {
        $( ".bg-video-container" ).fadeOut( "slow", function() {
        $( "#enter" ).fadeOut( "slow");
        $(".swiper-slide-active video").trigger('pause');
        setTimeout(function(){
          window.location.href='/main.html';
        }, 1000);

        });
    });





//     const video = document.querySelector('video');
//     video.onsuspend = (event) => {
//   console.log('Data loading has been suspended.');
//   $(".warning-text").text("autoplay not possible");
// };





// const videoElement = document.getElementById('1video');
//         videoElement.addEventListener('suspend', () => {
//             // suspend invoked
//             // show play button
//             $(".warning-text").text("autoplay not possible");
//         });
//
//         videoElement.addEventListener('play', () => {
//             // video is played
//             // remove play button UI
//         });
//
//
//         $('body').on('click touchstart', function () {
//                     const videoElement = document.getElementById('1video');
//                     if (videoElement.playing) {
//                         // video is already playing so do nothing
//                     }
//                     else {
//                         // video is not playing
//                         // so play video now
//                         videoElement.play();
//                     }
//             });

          // video.addEventListener('suspend', () => {
            //     console.log("autoplay possible");
            //     $(".warning-text").text("autoplay not possible");
            //
            // });
            //
            // videoElement.addEventListener('play', () => {
            //   console.log("autoplay not possible");
            //   $(".warning-conntainer p").text("autoplay possible");
            //
            // });



            // const videoElement = document.getElementById('video');
            //
            // videoElement.addEventListener('autostartNotAllowed', (e) => {
            //   console.log(e)
            //   $(".warning-conntainer p").text("autoplay not possible");
            //
            //   // message: "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
            //   // name: "NotAllowedError"
            //   // reason: "autoplayDisabled"
            // });
            //
            // videoElement.addEventListener('play', () => {
            //   $(".warning-conntainer p").text("autoplay possible");
            //   // remove play UI
            // });

//             const videoElement = document.getElementById('video');
//
// videoElement.addEventListener('suspend', () => {
//   // suspended loading. Show play UI (or fallback image)..
//    $(".warning-conntainer p").text("autoplay not possible");
//    console.log("not possible");
// });
//
// videoElement.addEventListener('play', () => {
//    $(".warning-conntainer p").text("autoplay possible");
//    console.log("possible");
//
//   // remove play UI
// });


});












window.addEventListener('load', function () {
  $(".overlay-opac").animate({opacity: 0.3}, 1000);
})


    $(document).ready(function() {

      var w = window.innerWidth;
var h = window.innerHeight;
$(".into").css("height",h);
$(".into").css("overflow","hidden");



var lastScrollTop = 0;

window.addEventListener("scroll", function(){
   var st = window.pageYOffset || document.documentElement.scrollTop;
   if (st > lastScrollTop){
       $(".menu-holder-bottom").css("bottom","-100%");
   } else {
      $(".menu-holder-bottom").css("bottom","0");

   }
   lastScrollTop = st;
}, false);







window.addEventListener("scroll", function(){
  var scrolly = window.pageYOffset;
  var width = $(window).width();

  if(width >= 670){


    if(scrolly >= 10){
      $(".menu-holder-bottom").removeClass( "animate__fadeInUp" );
      $(".menu-holder-bottom").css("display","none");
      $("animate__fadeInUp")
    }
    else{
      $(".menu-holder-bottom").css("display","flex");
      $(".menu-holder-bottom").addClass( "animate__fadeInUp" );


    }
  }



});










$('.swiper-slide').click(function(){
  var kids = $(this).find("video").attr("id");
  var player = $(this).find("video");
  var my_video_id = videojs(kids);

  if (my_video_id.player_.paused()) {
        my_video_id.player_.play();
        $(".video-js").css("cursor","url(/img/cursor-pause.svg) 20 20, auto")
  } else {
        my_video_id.player_.pause();
        $(".video-js").css("cursor","url(/img/cursor-play.svg) 20 20, auto")
  }

 console.log(kids);
});

        });
